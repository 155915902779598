import React, {Component} from 'react';
import {
    Segment,
    Grid,
    Header,
    Checkbox,
    Button,
    Accordion,
    Responsive,
    Loader,
    Dimmer,
    Message
} from "semantic-ui-react";
import {connect} from "react-redux";
import {
    startToMaintenanceRoom,
    updateMaintenanceTask
} from "../actions/propertyActions";
import {isValidCleanableTypeName, FIVE_MINUTES, getCleanable} from "../utils/common";
import {setGoBackCallback} from "../actions/appActions";
import {roomStatuses} from "../components/cleanableCards/types";
import axios from "axios";


const mapStateToProps = (state, ownProps) => {
    const {properties, user} = state;
    const {selectedPropertyIds, date} = properties;

    return {
        ...ownProps,
        loadingRoomData: !!properties.loading,
        selectedPropertyIds,
        selectedDate: date,
        selectedProperties: properties.propertyList,
        displayText: user.displayText,
        currentRoomStatus: properties.currentRoomStatus,
    }
};

class MaintenanceTaskDetails extends Component {
    constructor(props) {
        super(props);
        const {match, history} = props;

        const id = parseInt(match.params.id, 10);
        const typeName = match.params.typeName;
        const invalidTypeName = !isValidCleanableTypeName(typeName);
        const propertyId = parseInt(match.params.propertyId, 10);

        if (isNaN(id) || !typeName || typeName === '' || invalidTypeName || isNaN(propertyId)) {
            history.push("/");
        }

        this.state = {
            id,
            propertyId,
            typeName,
            changedTaskStatus: false,
            cleaningKeepAliveInterval: null,
        }
    }

    componentDidMount() {
        const {dispatch} = this.props;
        const {id, propertyId, typeName} = this.state;

        dispatch(startToMaintenanceRoom(propertyId, typeName, id));
        dispatch(setGoBackCallback(this.handleBack));

        this.setState({
            cleaningKeepAliveInterval: setInterval(
                () => axios.put("/ws/housekeepingapi/checkMaintenanceRoomStatus", {id: id}),
                FIVE_MINUTES
            )
        });
    }

    componentWillUnmount() {
        const {cleaningKeepAliveInterval,} = this.state;
        clearInterval(cleaningKeepAliveInterval);
        this.props.dispatch(setGoBackCallback(() => {}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {changedTaskStatus, displaySuccessMessage} = this.state;
        const {currentRoomStatus, history, location, displayText} = this.props;

        const showServiceRequestSubmitSuccess = (!!location && !!location.state && location.state.showServiceRequestSubmitSuccess) || false;
        if(!!showServiceRequestSubmitSuccess && !displaySuccessMessage){
            this.hideSuccessMessage = setTimeout(() => this.setState({displaySuccessMessageText: ''}), 3000);
            this.setState({
                displaySuccessMessage: true,
                displaySuccessMessageText: displayText.serviceRequestSuccessful
            })
        }

        if(changedTaskStatus && currentRoomStatus === roomStatuses.CLEAN){
            history.goBack();
        }
    }

    handleCheckboxOnClick = async (taskId) => {
        const {dispatch,  selectedProperties} = this.props;
        const {id, propertyId, typeName} = this.state;
        const cleanable = getCleanable(selectedProperties, id, typeName, propertyId);
        const task = cleanable.tasks.filter(t => t.id === taskId)[0];
        task.completed = !task.completed;
        this.setState({changedTaskStatus: true});
        dispatch(updateMaintenanceTask(propertyId, typeName, cleanable));
    };

    handleClick = (index) => {
        const {activeIndex} = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({activeIndex: newIndex})
    };

    handleBack = async() => {
        const {history} = this.props;
        history.push("/");
    };

    renderTasks = (tasks) => {
        const {activeIndex} = this.state;
        const {displayText} = this.props;

        return (
            <Accordion>
                {!!tasks && Array.isArray(tasks) && tasks.map((t, i) =>
                    <Segment key={i}>
                        <Accordion.Title active={activeIndex === i} index={i}>
                            <Checkbox
                                label={t.name}
                                checked={t.completed}
                                onClick={() => {
                                    if(activeIndex === i){
                                        this.handleCheckboxOnClick(t.id)
                                    }
                                    this.handleClick(i)
                                }}
                            />
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === i}>
                            <ul>{!!t.subtask && Array.isArray(t.subtask) && t.subtask.map((e, idx) => <li key={idx}>{e}</li>)}</ul>
                            {(!t.subtask || (!!t.subtask && Array.isArray(t.subtask) && t.subtask.length === 0)) ? (
                                <ul><li>{displayText.noSubtasksSpecified}</li></ul>
                            ) : null
                            }
                        </Accordion.Content>
                    </Segment>
                )}
            </Accordion>
        )
    };

    render() {
        const {displayText, selectedProperties} = this.props;
        const {id, typeName, propertyId, loading, displaySuccessMessageText} = this.state;
        const cleanable = getCleanable(selectedProperties, id, typeName, propertyId);

        return (
            <Segment raised>
                <Dimmer inverted active={!!loading}>
                    <Loader>{displayText.loading}</Loader>
                </Dimmer>

                <Header as={'h3'} style={{margin: '0.5em'}}>
                    {displayText.maintenanceTasks} - {cleanable.description}
                </Header>
                <Header as={'h4'}
                        style={{marginLeft: '0.5em', marginBottom: '1em'}}>{displayText.cleaningTasks}</Header>

                {this.renderTasks(cleanable.tasks)}

                <Grid style={{marginTop: '0.5em'}}>
                    {!!displaySuccessMessageText && displaySuccessMessageText.length > 0 &&
                    <Grid.Row>
                        <Grid.Column>
                            <Message positive onDismiss={() => this.setState({displaySuccessMessageText: ''})}>
                                {displaySuccessMessageText}
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                    }
                    <Responsive as={Grid.Row} minWidth={Responsive.onlyComputer.minWidth}>
                        <Grid.Column textAlign={"right"}>
                            <Button secondary onClick={this.handleBack}>{displayText.back}</Button>
                        </Grid.Column>
                    </Responsive>
                </Grid>
            </Segment>
        );
    }
}

export default connect(mapStateToProps)(MaintenanceTaskDetails);
