import {fulfilled, pending, rejected} from "../utils/asyncStatusGenerator";
import {userActionTypes} from "../actions/userActions";
import {saveToken} from "../utils/localStorage";
import {getStrings} from "../config/displayText";

const initialState = {
    defaultPropertyId: null,
    availableProperties: [],
    firstName: "",
    lastName: "",
    loginPending: false,
    loginError: '',
    lastVisitedAccordionIdx: 0,
    language: "en",
    displayText: getStrings("en"),
};

export const userReducer = (state = initialState, action) => {
    const {payload} = action;
    const data = !!payload && payload.data;
    switch (action.type) {
        case pending(userActionTypes.LOGIN):
            return {
                ...state,
                loginPending: true,
                loginError: '',
            };
        case fulfilled(userActionTypes.LOGIN):
            const {defaultPropertyId, properties, firstName, lastName, token, language} = data;
            const availableProperties = properties.map(p => ({id: p.id, name: p.propertyName}));

            if(!!token && token.length > 0){
                saveToken(token);
            }

            let lang = (!language || (!!language && language.length === 0))? "ENGLISH": language;
            lang = getShortLanguageName(lang);

            return {
                ...state,
                loginPending: false,
                loginError: '',
                defaultPropertyId,
                availableProperties,
                firstName,
                lastName,
                language: lang,
                displayText: getStrings(lang)
            };
        case rejected(userActionTypes.LOGIN):
            return {
                ...state,
                loginPending: false,
                loginError: payload
            };

        case fulfilled(userActionTypes.LOGOUT):
            return {
                ...state,
                loginPending: false,
                loginError: payload,
            };


        case fulfilled(userActionTypes.SET_LAST_VISITED_ACCORDION):
            return {
                ...state,
                lastVisitedAccordionIdx: payload,
            };


        case fulfilled(userActionTypes.UPDATE_LANGUAGE_STRINGS):
            return {
                ...state,
                displayText: getStrings(state.language)
            };

        default:
            return state;
    }
};

const getShortLanguageName = (lang) => {
    switch(lang){
        case "SPANISH":
            lang = "es";
            break;
        case "ENGLISH":
        default:
            lang = "en";
    }

    return lang;
};
