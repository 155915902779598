import React, {Component} from 'react';
import {Card} from "semantic-ui-react";
import {connect} from "react-redux";
import {getSelectedProperties} from "../utils/common";
import DirtyOnlySwitch from "../components/dirtyOnlySwitch";
import CommonAreaTaskCard from "../components/cleanableCards/commonAreaTaskCard";
import {cardTypes, commonAreaTaskStatuses} from "../components/cleanableCards/types";

const mapStateToProps = (state, ownProps) => {
    const {properties} = state;
    const {propertyList, selectedPropertyIds} = properties;
    const selectedProperties = getSelectedProperties(propertyList, selectedPropertyIds);

    return {
        ...ownProps,
        loadingRoomsData: !!properties.loading,
        selectedProperties,
    }
};

class CommonAreaTasks extends Component {
    render() {
        const {selectedProperties} = this.props;

        let propertyName = null;
        let propertyId = null;
        let sortedCommonAreaTaskDataList = null;
        selectedProperties.forEach(sp => {
            if (!!sp && !!sp.commonAreaTaskDataList) {
                propertyName = sp.propertyName;
                propertyId = sp.id;
                const tasksWithTime = sp.commonAreaTaskDataList.filter(t => !!t.requestedCompletionTime);
                const tasksWithoutTime = sp.commonAreaTaskDataList.filter(t => !t.requestedCompletionTime);

                sortedCommonAreaTaskDataList = tasksWithTime.sort((a, b) => {
                    try {
                        const aTime = parseInt(a.requestedCompletionTime, 10);
                        const bTime = parseInt(b.requestedCompletionTime, 10);
                        return aTime - bTime;
                    } catch (e) {
                        return 0;
                    }
                });

                tasksWithoutTime.sort((a, b) => {
                    return a.commonAreaName > b.commonAreaName;
                });

                sortedCommonAreaTaskDataList = sortedCommonAreaTaskDataList.concat(tasksWithoutTime);
            }
        });

        return (
            <div>
                <DirtyOnlySwitch typeName={cardTypes.COMMON_AREA_TASKS}/>

                <Card.Group>
                    {!!sortedCommonAreaTaskDataList && sortedCommonAreaTaskDataList.length > 0 &&
                    (sortedCommonAreaTaskDataList.map((curTask, caIdx) => {
                        const {name, subtask, requestedCompletionTime, completed, ...commonAreaProps} = curTask;
                        let militaryTime = (!!requestedCompletionTime) ? requestedCompletionTime + "hs" : null;
                        return (
                            <CommonAreaTaskCard key={caIdx}
                                                propertyName={propertyName}
                                                commonAreaTaskName={name}
                                                requestedCompletionTime={militaryTime}
                                                taskStatus={completed ? commonAreaTaskStatuses.DONE : commonAreaTaskStatuses.INCOMPLETE}
                                                subTask={subtask}
                                                {...commonAreaProps}
                                                propertyId={propertyId}/>
                        )
                    }))
                    }
                </Card.Group>
            </div>
        );
    }
}

export default connect(mapStateToProps)(CommonAreaTasks);
