import {newFulfilledAction, newPendingAction, newRejectedAction} from "../utils/asyncActionGenerator";
import axios from 'axios';

export const appActionTypes = {
    SET_GO_BACK_CALLBACK: "SET_GO_BACK_CALLBACK",
    FETCH_SERVICE_TYPES: "FETCH_SERVICE_TYPES",
};

export function setGoBackCallback(callback){
    return async (dispatch) => dispatch(newFulfilledAction(appActionTypes.SET_GO_BACK_CALLBACK, callback));
}


export function fetchServiceTypes(){
    return async (dispatch) => {
        dispatch(newPendingAction(appActionTypes.FETCH_SERVICE_TYPES));
        try {
            const axiosResult = await axios.get("/ws/housekeepingapi/listServiceRequestTypeData");

            return dispatch(newFulfilledAction(appActionTypes.FETCH_SERVICE_TYPES, axiosResult));
        } catch (error) {
            return dispatch(
                newRejectedAction(appActionTypes.FETCH_SERVICE_TYPES,
                    `Error fetching service types. ${error}`
                )
            );
        }
    }
}