import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Accordion, Dimmer, Icon, Loader} from "semantic-ui-react";
import OutInCleanings from "./outincleanings";
import ServiceCleanings from "./serviceCleanings";
import OutCleanings from "./outcleanings";
import {setLastVisitedAccordion} from "../actions/userActions";
import {getSelectedProperties} from "../utils/common";
import {startPeriodicPropertiesFetch} from "../actions/propertyActions";
import {commonAreaTaskStatuses, roomStatuses, tasksStatuses} from "../components/cleanableCards/types";
import ManualTasks from "./manualTasks";
import CommonAreaTasks from "./commonAreaTasks";
import MaintenanceTasks from "./maintenanceTasks";
// import SwipeableViews from 'react-swipeable-views';
// import {Swipeable} from "react-touch";


const mapStateToProps = (state, ownProps) => {
    const {user, properties} = state;
    const {propertyList, selectedPropertyIds, date, fetchPropertiesDataInterval} = properties;
    const selectedProperties = getSelectedProperties(propertyList, selectedPropertyIds);
    const qtyDirtyServiceCleanings = {vacant:0, all: 0, displayTotalOnly: true};
    const qtyDirtyCoCiCleanings = {vacant:0, all: 0, displayTotalOnly: false};
    const qtyDirtyCoCleanings = {vacant:0, all: 0, displayTotalOnly: false};
    const qtyDirtyManualTasks = {vacant:0, all: 0, displayTotalOnly: true};
    const qtyDirtyCommonAreaTasks = {vacant:0, all: 0, displayTotalOnly: true};
    const qtyIncompleteMaintenanceTasks = {vacant:0, all: 0, displayTotalOnly: true};

    const isTaskIncomplete = (t, field="maintenanceTaskStatus") => t[field] === tasksStatuses.INCOMPLETE || t[field] === tasksStatuses.IN_PROGRESS || t[field] === commonAreaTaskStatuses.INCOMPLETE || t[field] === commonAreaTaskStatuses.IN_PROGRESS;
    const isRoomDirty = (r, field="roomStatus") => r[field] === roomStatuses.DIRTY || r[field] === roomStatuses.CLEANING || r[field] === roomStatuses.CLEANING_PAUSED;
    selectedProperties.forEach(sp => {
        const scDirty = (!!sp.serviceCleanings && sp.serviceCleanings.filter(r => isRoomDirty(r))) || [];
        const coCiDirty = (!!sp.checkoutCheckinCleanings && sp.checkoutCheckinCleanings.filter(r => isRoomDirty(r))) || [];
        const coDirty = (!!sp.checkoutCleanings && sp.checkoutCleanings.filter(r => isRoomDirty(r))) || [];
        const mtDirty = (!!sp.manualTasks && sp.manualTasks.filter(r => isRoomDirty(r))) || [];
        const caIncomplete = (!!sp.commonAreaTaskDataList && sp.commonAreaTaskDataList.filter(r => isTaskIncomplete(r, "status"))) || [];
        const mtIncomplete = (!!sp.roomWithMaintenanceTaskDataList && sp.roomWithMaintenanceTaskDataList.filter(t => isTaskIncomplete(t))) || [];

        qtyDirtyServiceCleanings.all += scDirty.length;
        qtyDirtyCoCiCleanings.all += coCiDirty.length;
        qtyDirtyCoCleanings.all += coDirty.length;
        qtyDirtyManualTasks.all += mtDirty.length;
        qtyDirtyCommonAreaTasks.all += caIncomplete.length;
        qtyIncompleteMaintenanceTasks.all += mtIncomplete.length;

        qtyDirtyServiceCleanings.vacant += scDirty.filter(r => !!r.vacant).length;
        qtyDirtyCoCiCleanings.vacant += coCiDirty.filter(r => !!r.vacant).length;
        qtyDirtyCoCleanings.vacant += coDirty.filter(r => !!r.vacant).length;
        qtyDirtyManualTasks.vacant += mtDirty.filter(r => !!r.vacant).length;
        qtyDirtyCommonAreaTasks.vacant += caIncomplete.filter(r => !!r.vacant).length;
        qtyIncompleteMaintenanceTasks.vacant += mtIncomplete.filter(r => !!r.vacant).length;
    });

    return {
        ...ownProps,
        loadingRoomsData: !!properties.loading,
        qtyDirtyServiceCleanings,
        qtyDirtyCoCiCleanings,
        qtyDirtyCoCleanings,
        qtyDirtyManualTasks,
        qtyDirtyCommonAreaTasks,
        qtyIncompleteMaintenanceTasks,
        activeAccordionIndex: user.lastVisitedAccordionIdx,
        selectedPropertyIds,
        selectedDate: date,
        fetchPropertiesDataInterval,
        displayText: user.displayText,
    }
};

class Home extends Component {
    constructor(props){
        super(props);
        this.state={
            activeSwipeableViewIndex: 0,
            previousTopScrollPosition: 0,
        }
    }

    componentDidMount() {
        const {dispatch, selectedPropertyIds, selectedDate} = this.props;
        dispatch(startPeriodicPropertiesFetch(selectedPropertyIds, selectedDate));
    }

    componentWillUnmount() {
        const {fetchPropertiesDataInterval} = this.props;
        clearInterval(fetchPropertiesDataInterval);
    }

    handleAccordionClick = (e, titleProps) => {
        const {dispatch, activeAccordionIndex} = this.props;
        const {index} = titleProps;
        const newIndex = activeAccordionIndex === index ? -1 : index;

        dispatch(setLastVisitedAccordion(newIndex));
    };

    buildAccordionPanels = (accordionData) => {
        const {activeAccordionIndex} = this.props;
        let jsx = [];

        accordionData.forEach(ac => {
            const {title, index, content, dirtyQtyObj} = ac;
            const {vacant: vacantDirtyQty, all: allDirtyQty, displayTotalOnly} = dirtyQtyObj;
            let details = `(${vacantDirtyQty} of ${allDirtyQty})`;
            if(displayTotalOnly){
                details = `(${allDirtyQty})`;
            }

            const curJsx =
                <React.Fragment key={index}>
                    <Accordion.Title active={activeAccordionIndex === index} index={index}
                                     onClick={this.handleAccordionClick}>
                        <Icon name={'dropdown'}/>{`${title} ${details}`}
                    </Accordion.Title>

                    <Accordion.Content active={activeAccordionIndex === index}>
                        {content}
                    </Accordion.Content>
                </React.Fragment>;

            jsx.push(curJsx)
        });

        return jsx;
    };

    /*
    handleOnChangeIndex = (index) => {
        const {previousTopScrollPosition} = this.state;
        const currentTopScrollPosition  = window.pageYOffset || document.documentElement.scrollTop;

        window.scrollTo(0, previousTopScrollPosition);
        this.setState({activeSwipeableViewIndex: index, previousTopScrollPosition: currentTopScrollPosition})
    };
    */

    render() {
        const {
            displayText,
            loadingRoomsData,
            qtyDirtyServiceCleanings,
            qtyDirtyCoCiCleanings,
            qtyDirtyCoCleanings,
            qtyDirtyManualTasks,
            qtyDirtyCommonAreaTasks,
            qtyIncompleteMaintenanceTasks,
        } = this.props;
        let idx = 0;
        const accordionPanels = this.buildAccordionPanels([
            {index: idx++, dirtyQtyObj: qtyDirtyCoCiCleanings, content: <OutInCleanings/>, title: displayText.coCiCleanings},
            {index: idx++, dirtyQtyObj: qtyDirtyCoCleanings, content: <OutCleanings/>, title: displayText.coCleanings},
            {index: idx++, dirtyQtyObj: qtyDirtyServiceCleanings, content: <ServiceCleanings/>, title: displayText.serviceCleanings},
            {index: idx++, dirtyQtyObj: qtyDirtyManualTasks, content: <ManualTasks/>, title: displayText.manualTasks},
            {index: idx++, dirtyQtyObj: qtyDirtyCommonAreaTasks, content: <CommonAreaTasks/>, title: displayText.commonAreas},
            {index: idx, dirtyQtyObj: qtyIncompleteMaintenanceTasks, content: <MaintenanceTasks/>, title: displayText.maintenanceTasks},
        ]);
        //const {activeSwipeableViewIndex} = this.state;

        const swipeableStyle = {marginLeft:'0.06em', marginRight: '0.06em', marginBottom: '0.06em'};
        return (
            <React.Fragment>
            {/*
            <SwipeableViews
                enableMouseEvents
                onChangeIndex={this.handleOnChangeIndex}
                index={activeSwipeableViewIndex}
                children={[
            */}
                    <div index={0} style={swipeableStyle}>
                        {/*<Swipeable onSwipeLeft={() => {this.handleOnChangeIndex(1)}}>*/}
                            <Accordion styled fluid>
                                <Dimmer inverted active={loadingRoomsData}>
                                    <Loader>{displayText.loading}</Loader>
                                </Dimmer>

                                {accordionPanels}
                            </Accordion>
                            {/*</Swipeable>*/}
                    </div>
                    {/*
                    <div index={1} style={swipeableStyle}>
                        <Swipeable onSwipeRight={() => {this.handleOnChangeIndex(0)}}>
                            <Segment>
                                <Card>
                                    <Card.Header>
                                        <div><h3>Something</h3></div>
                                    </Card.Header>
                                    <Card.Content>one two three four</Card.Content>
                                    <Card.Content extra><Button>Tap</Button></Card.Content>
                                </Card>
                            </Segment>
                        </Swipeable>
                    </div>
                ]}/>*/}
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(Home));
