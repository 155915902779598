export const cardTypes = {
    SERVICE_CLEANINGS: "serviceCleanings",
    CHECKOUT_CHECKIN_CLEANINGS: "checkoutCheckinCleanings",
    CHECKOUT_CLEANINGS: "checkoutCleanings",
    MANUAL_TASKS: "manualTasks",
    COMMON_AREA_TASKS: "commonAreaTaskDataList",
    MAINTENANCE_TASKS: "roomWithMaintenanceTaskDataList",
};

export const roomStatuses = {
    DIRTY: "Dirty",
    CLEANING: "Cleaning",
    CLEANING_PAUSED: "Cleaning Paused",
    READY: "Ready",
    CLEAN: "Clean",
};

export const tasksStatuses = {
    INCOMPLETE: "Tasks incomplete",
    IN_PROGRESS: "Tasks In progress",
    DONE: "Tasks Done",
};

export const commonAreaTaskStatuses = {
    INCOMPLETE: "DIRTY",
    IN_PROGRESS: "INPROGRESS",
    DONE: "DONE",
};
