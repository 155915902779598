import {Component} from 'react';
import {axiosConfig} from "../config/axios";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";

class Config extends Component {
    componentWillMount() {
        const {dispatch, history} = this.props;
        axiosConfig(dispatch, history);
    }

    render(){return this.props.children}
}

export default withRouter(connect()(Config))