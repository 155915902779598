import React from "react";
import RoomCard from "./roomCard";
import {cardTypes} from "./types";

const ManualTasksCard = ({propertyName, propertyId, roomNumber, id, roomStatus, roomTypeName, tasks, markAsDoNotDisturb}) => {
    const showStartToCleanBtn = !!tasks && tasks.length > 0;
    return (
        <RoomCard roomNumber={roomNumber}
                  tasks={tasks}
                  showStartToCleanBtn={showStartToCleanBtn}
                  typeName={cardTypes.MANUAL_TASKS}
                  propertyName={propertyName}
                  propertyId={propertyId}
                  roomStatus={roomStatus}
                  roomTypeName={roomTypeName}
                  id={id}
                  showDoNotDisturbBtn={true}
                  markAsDoNotDisturb={markAsDoNotDisturb}
        />
    )
};

export default ManualTasksCard;
