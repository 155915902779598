import React, {Component} from 'react';
import {connect} from "react-redux";
import {Checkbox, Grid} from "semantic-ui-react";
import {setShowDirtyOnly} from "../actions/propertyActions";
import {cardTypes} from "./cleanableCards/types";

const mapStateToProps = (state, ownProps) => {
    const {user, properties} = state;

    return {
        ...ownProps,
        showDirtyOnly: properties.showDirtyOnly,
        displayText: user.displayText,
    }
};

class DirtyOnlySwitch extends Component {
    toggle = () => {
        const {showDirtyOnly, dispatch} = this.props;
        dispatch(setShowDirtyOnly(!showDirtyOnly));
    };

    render() {
        const {showDirtyOnly, displayText, typeName} = this.props;
        let label = displayText.dirtyOnly;
        if(typeName === cardTypes.MAINTENANCE_TASKS || typeName === cardTypes.COMMON_AREA_TASKS){
            label = displayText.incompleteOnly;
        }

        return (
            <Grid textAlign='right' columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Checkbox
                            onChange={this.toggle}
                            checked={showDirtyOnly}
                            style={{marginBottom: '1em'}}
                            toggle
                            label={label}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(mapStateToProps)(DirtyOnlySwitch);
