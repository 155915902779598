import {fulfilled, pending, rejected} from "../utils/asyncStatusGenerator";
import {appActionTypes} from "../actions/appActions";

const initialState = {
    goBackCallback: () => {},
    serviceTypes: {
        loadingServiceTypes: false,
        loadingServiceTypesError: '',
        data: [],
    }
};

export const appReducer = (state = initialState, action) => {
    const {payload} = action;
    switch (action.type) {

        case fulfilled(appActionTypes.SET_GO_BACK_CALLBACK):
            return {
                ...state,
                goBackCallback : payload,
            };

        case pending(appActionTypes.FETCH_SERVICE_TYPES):
            return {
                ...state,
                serviceTypes:{
                    loadingServiceTypes: true,
                    loadingServiceTypesError: '',
                    data: [],
                }
            };
        case fulfilled(appActionTypes.FETCH_SERVICE_TYPES):
            return {
                ...state,
                serviceTypes:{
                    loadingServiceTypes: false,
                    loadingServiceTypesError: '',
                    data: payload.data,
                }
            };
        case rejected(appActionTypes.FETCH_SERVICE_TYPES):
            return {
                ...state,
                serviceTypes:{
                    loadingServiceTypes: false,
                    loadingServiceTypesError: payload.data,
                    data: state.serviceTypes.data,
                }
            };


        default:
            return state;
    }
};

