import React, {Component} from 'react';
import {Button, Form, Select, TextArea, Segment, Grid, Header, Responsive, Loader, Dimmer, Message} from "semantic-ui-react";
import {connect} from "react-redux";
import {fetchServiceTypes, setGoBackCallback} from "../actions/appActions";
import axios from 'axios';


const mapStateToProps = (state, ownProps) => {
    const {user, app} = state;
    return {
        ...ownProps,
        displayText: user.displayText,
        serviceTypes: (!!app && !!app.serviceTypes && app.serviceTypes.data) || [],
        serviceTypesLoading: (!!app && !!app.serviceTypes && app.serviceTypes.loadingServiceTypes) || false,
    }
};

class ServiceRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomId: null,
            validationError: '',
        };
    }

    goBack = () => {
        const {history, location} = this.props;
        const previousRoute = (!!location && !!location.state && location.state.previousRoute) || false;
        history.push({pathname: previousRoute, state: {showServiceRequestSubmitSuccess: false}});
    };

    componentDidMount() {
        const {dispatch, history, serviceTypes, location} = this.props;
        const previousRoute = (!!location && !!location.state && location.state.previousRoute) || false;
        if(!previousRoute){
            history.goBack();
        }


        dispatch(setGoBackCallback(this.goBack));
        if(!serviceTypes || serviceTypes.length === 0){
            dispatch(fetchServiceTypes());
        }

        const id = parseInt(this.props.match.params.roomId, 10);
        if (!isNaN(id)) {
            this.setState({
                roomId: id,
                previousRoute,
            })
        } else {
            history.goBack()
        }
    }

    handleChange = (e, {name, value}) => this.setState({[name]: value, validationError: ''});

    handleSubmit = async () => {
        const {displayText, history} = this.props;
        const {selectedTypeOption, writtenRequestText, roomId, previousRoute} = this.state;
        if (!selectedTypeOption || selectedTypeOption.length === 0){
            this.setState({validationError: displayText.pleaseSelectAType});
            return;
        }
        if (!writtenRequestText || writtenRequestText.length === 0){
            this.setState({validationError: displayText.requestCantBeEmpty});
            return;
        }

        const payload = {
            roomId: roomId,
            serviceRequestTypeId: selectedTypeOption,
            request: writtenRequestText,
        };

        const axiosREsult = await axios.post("/ws/housekeepingapi/createRoomServiceRequestData", payload);
        if(!!axiosREsult && axiosREsult.data){
            history.push({pathname: previousRoute, state: {showServiceRequestSubmitSuccess: true}});
        }
    };

    render() {
        const {displayText, serviceTypesLoading, serviceTypes, match} = this.props;
        const {validationError} = this.state;
        const typeOptions = serviceTypes.map(st => {return{
            key: st.id, text: st.category, value: st.id
        }});
        const roomNumber = parseInt(match.params.roomNumber, 10);

        return (
            <Segment raised>
                <Dimmer inverted active={!!serviceTypesLoading}>
                    <Loader>{displayText.loading}</Loader>
                </Dimmer>

                <Header as={'h3'} style={{margin: '0.5em'}}>
                    Room #{roomNumber}
                </Header>
                <Header as={'h4'} style={{marginLeft: '0.5em'}}>{displayText.newServiceRequest}</Header>

                <Form style={{marginLeft: '0.5em'}} onSubmit={this.handleSubmit}>
                    <Form.Field name={"selectedTypeOption"} control={Select}
                                label={displayText.type} options={typeOptions}
                                placeholder={displayText.type}
                                onChange={this.handleChange}/>

                    <Form.Input name={"writtenRequestText"} control={TextArea}
                                label={displayText.request}
                                onChange={this.handleChange}/>

                    {validationError.length > 0 && <Message negative>
                        {validationError}
                    </Message>}

                    <Grid>
                        <Grid.Column textAlign={"right"}>
                            <Responsive as={Button}
                                        secondary onClick={this.goBack}
                                        minWidth={Responsive.onlyComputer.minWidth}>
                                {displayText.cancel}
                            </Responsive>
                            <Button primary type={'submit'}>{displayText.submit}</Button>
                        </Grid.Column>
                    </Grid>
                </Form>
            </Segment>
        );
    }
}

export default connect(mapStateToProps)(ServiceRequest);
