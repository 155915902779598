import React, {Component, createRef} from "react";
import {Dropdown, Menu, Loader, Dimmer, Checkbox, Popup, Icon, Responsive} from "semantic-ui-react";
import {connect} from "react-redux";
import {fetchPropertiesData} from "../actions/propertyActions";
import {getToken} from "../utils/localStorage";
import {logout} from "../actions/userActions";
import {withRouter} from "react-router-dom";
import {DateInput} from "semantic-ui-calendar-react";
import moment from "moment";

const mapStateToProps = (state, ownProps) => {
    const {properties, user, app} = state;
    return {
        ...ownProps,
        username: user.firstName,
        selectedDate: properties.date,
        loading: properties.loading,
        properties: properties.propertyList,
        selectedPropertyIds: properties.selectedPropertyIds,
        defaultPropertyId: user.defaultPropertyId,
        availableProperties: user.availableProperties,
        displayText: user.displayText,
        language: user.language,
        goBackCallback: app.goBackCallback,
    }
};

class HeaderBar extends Component {
    constructor(props) {
        super(props);
        const authToken = getToken();
        let selectedProperties = [];

        if (!authToken) {
            this.dispatchLogout("Unauthorized")
        } else {
            const {defaultPropertyId} = props;

            if (!!defaultPropertyId) {
                selectedProperties.push(defaultPropertyId);
            }
        }

        this.state = {
            selectedProperties,
            openPropertiesPopUp: false,
            openDatePopUp: false,
        }
    }

    dispatchLogout = (reason) => {
        const {dispatch, history} = this.props;
        reason = (!reason) ? "" : reason;
        dispatch(logout(reason, history))
    };

    onDateChange = (e, {name, value}) => {
        const {dispatch, selectedPropertyIds} = this.props;
        if (name === "selectedDate") {
            this.toggleDatePopUp();
            dispatch(fetchPropertiesData(selectedPropertyIds, value));
        }
    };

    propertiesContextRef = createRef();
    dateContextRef = createRef();
    togglePropertiesPopUp = () => this.setState(prevState => ({openPropertiesPopUp: !prevState.openPropertiesPopUp}));
    toggleDatePopUp = () => this.setState(prevState => ({openDatePopUp: !prevState.openDatePopUp}));

    handlePropertyChange = ({checked}, propertyId) => {
        const {dispatch, selectedDate, selectedPropertyIds} = this.props;
        let propertyIds = selectedPropertyIds;
        if (checked) {
            selectedPropertyIds.push(propertyId);
        } else {
            propertyIds = selectedPropertyIds.filter(spi => spi !== propertyId)
        }

        this.setState({openPropertiesPopUp: false});
        dispatch(fetchPropertiesData(propertyIds, selectedDate));
    };

    renderPropertiesMenu = (iconOnly) => {
        const {availableProperties, selectedPropertyIds, displayText} = this.props;
        const propertyOptions = !!availableProperties ? availableProperties.map((p) => ({
            id: p.id,
            text: p.name,
        })) : [];

        const dd = iconOnly ? <Dropdown item icon={'home'} onClick={this.togglePropertiesPopUp}/> :
            <Dropdown item text={displayText.properties} onClick={this.togglePropertiesPopUp}/>;
        return (
            <React.Fragment>
                <div ref={this.propertiesContextRef}>{dd}</div>
                <Popup open={this.state.openPropertiesPopUp}
                       context={this.propertiesContextRef}
                       style={{maxHeight: '90vh', overflowY: 'auto', marginTop: '0'}}>
                    {propertyOptions.map((po, i) =>
                        <div key={i}>
                            <Checkbox className={'spacedCheckbox'}
                                      label={po.text}
                                      checked={selectedPropertyIds.filter(spi => spi === po.id).length > 0}
                                      onChange={(e, obj) => this.handlePropertyChange(obj, po.id)}/>
                        </div>)}
                </Popup>
            </React.Fragment>
        )
    };

    handleGoBack = () => {
        const {goBackCallback, location} = this.props;
        if (location.pathname !== "/") {
            goBackCallback();
        }
    };

    render() {
        const {username, selectedDate, loading, displayText, location, language} = this.props;
        const isHome = location.pathname === "/";
        moment.locale(language);
        return (
            <React.Fragment>
                <Menu borderless fixed={'top'}>
                    <Menu.Item header onClick={this.handleGoBack}>
                        {!isHome && <Icon name={"arrow left"}/>}
                        Housekeeping
                    </Menu.Item>

                    <Menu.Menu position={'right'}>
                        <Responsive maxWidth={340}>{this.renderPropertiesMenu(true)}</Responsive>
                        <Responsive minWidth={340}>{this.renderPropertiesMenu(false)}</Responsive>

                        <div ref={this.dateContextRef}>
                            <Menu.Item onClick={this.toggleDatePopUp}>
                                <Icon name={'calendar alternate outline'}/>
                            </Menu.Item>
                        </div>
                        <Popup open={this.state.openDatePopUp}
                               context={this.dateContextRef}
                               style={{maxHeight: '90vh', overflowY: 'auto', marginTop: '0'}}>
                            <DateInput inline
                                       name={"selectedDate"}
                                       icon={false}
                                       popupPosition={"bottom center"}
                                       hideMobileKeyboard={true}
                                       animation={'none'}
                                       marked={[new Date()]}
                                       markColor='blue'
                                       onChange={this.onDateChange}
                                       value={selectedDate}
                                       className={'noiconinput'}
                                       style={{width: '7.8rem'}}
                            />
                        </Popup>

                        <Menu.Menu>
                            <Dropdown item icon={'user'}>
                                <Dropdown.Menu>
                                    <Dropdown.Header>{`${displayText.loggedInAs} ${username}`}</Dropdown.Header>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item onClick={this.dispatchLogout}>{displayText.logout}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Menu>
                    </Menu.Menu>
                </Menu>

                <Dimmer inverted active={!!loading}>
                    <Loader>{displayText.loading}</Loader>
                </Dimmer>

                {this.props.children}
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(HeaderBar));