import {Component} from 'react';
import {connect} from "react-redux";
import {updateLanguageStrings} from "../actions/userActions";

class Localized extends Component {
    constructor(props) {
        super(props);
        props.dispatch(updateLanguageStrings());
    }

    render(){
        return this.props.children
    }
}

export default connect()(Localized);
