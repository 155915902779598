import React, {Component} from 'react';
import {Card} from "semantic-ui-react";
import {connect} from "react-redux";
import {getSelectedProperties} from "../utils/common";
import DirtyOnlySwitch from "../components/dirtyOnlySwitch";
import ManualTasksCard from "../components/cleanableCards/manualTasksCard";
import {cardTypes} from "../components/cleanableCards/types";

const mapStateToProps = (state, ownProps) => {
    const {properties} = state;
    const {propertyList, selectedPropertyIds} = properties;
    const selectedProperties = getSelectedProperties(propertyList, selectedPropertyIds);

    return {
        ...ownProps,
        loadingRoomsData: !!properties.loading,
        selectedProperties,
    }
};

class ManualTasks extends Component {
    render() {
        const {selectedProperties} = this.props;

        return (
            <div>
                <DirtyOnlySwitch typeName={cardTypes.MANUAL_TASKS}/>

                <Card.Group>
                    {selectedProperties.map(sp => {
                        const propertyName = sp.propertyName;
                        const propertyId = sp.id;

                        if (!!sp && !!sp.manualTasks) {
                            return sp.manualTasks.map((c, idx) =>
                                <ManualTasksCard key={idx}
                                             {...c}
                                             propertyName={propertyName}
                                             propertyId={propertyId}
                                />)
                        }

                        return null;
                    })}
                </Card.Group>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ManualTasks);
