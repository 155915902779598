import React, {Component} from 'react';
import {Card, Grid} from "semantic-ui-react";
import {connect} from "react-redux";
import CheckOutCard from "../components/cleanableCards/checkOutCard";
import {getDayName, getSelectedProperties, nullUndef} from "../utils/common";
import moment from "moment";
import DirtyOnlySwitch from "../components/dirtyOnlySwitch";
import {cardTypes} from "../components/cleanableCards/types";

const mapStateToProps = (state, ownProps) => {
    const {properties, user} = state;
    const {propertyList, selectedPropertyIds} = properties;
    const selectedProperties = getSelectedProperties(propertyList, selectedPropertyIds);

    return {
        ...ownProps,
        loadingRoomsData: !!properties.loading,
        selectedProperties,
        language: user.language,
        displayText: user.displayText,
    }
};

class OutCleanings extends Component {
    renderCheckoutCleaningDetails = ({nextArrivalTime, currentArrivalTime, currentDepartureTime}) => {
        const {displayText, language} = this.props;
        const displayThis = !nullUndef(nextArrivalTime) && !nullUndef(currentArrivalTime) && !nullUndef(currentDepartureTime);
        let toRender = null;
        if (displayThis) {
            moment.locale(language);
            const nightsStayed = moment(currentDepartureTime).diff(moment(currentArrivalTime), 'days');

            const diff = moment(nextArrivalTime).diff(moment.now());
            const timeLeft = moment.duration(diff);
            const eta = timeLeft.humanize();

            toRender = (displayThis &&
                <Grid className={'clearBottomMargin, noBottomMarginRow'}>
                    <Grid.Row columns={'equal'}>
                        <Grid.Column textAlign={'left'}>
                            {displayText.nextCheckIn}:
                        </Grid.Column>
                        <Grid.Column textAlign={'right'}>
                            <b>{eta + ', ' + getDayName(nextArrivalTime)}</b>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={'equal'}>
                        <Grid.Column textAlign={'left'}>
                            {displayText.departingGuest}:
                        </Grid.Column>
                        <Grid.Column textAlign={'right'} verticalAlign={'bottom'}>
                            <b>{nightsStayed}</b>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }

        return toRender;
    };

    render() {
        const {selectedProperties} = this.props;

        return (
            <div>
                <DirtyOnlySwitch  typeName={cardTypes.CHECKOUT_CLEANINGS}/>

                <Card.Group>
                    {selectedProperties.map(sp => {
                        const propertyName = sp.propertyName;
                        const propertyId = sp.id;

                        if (!!sp && !!sp.checkoutCleanings) {
                            return sp.checkoutCleanings.map((c, idx) =>
                                <CheckOutCard key={idx}
                                              content={this.renderCheckoutCleaningDetails({...c})}
                                              {...c}
                                              propertyName={propertyName}
                                              propertyId={propertyId}
                                />)
                        }
                        return null;
                    })}
                </Card.Group>
            </div>
        );
    }
}

export default connect(mapStateToProps)(OutCleanings);
