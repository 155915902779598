import {newFulfilledAction, newPendingAction, newRejectedAction} from "../utils/asyncActionGenerator";
import axios from "axios";
import {propertiesActionTypes} from "./propertyActions";
import {deleteToken, deleteState} from "../utils/localStorage";

export const userActionTypes = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    SET_LAST_VISITED_ACCORDION: "SET_LAST_VISITED_ACCORDION",
    UPDATE_LANGUAGE_STRINGS: "UPDATE_LANGUAGE_STRINGS",
};

export function login(username, password) {
    return async (dispatch) => {
        dispatch(newPendingAction(userActionTypes.LOGIN));
        try {
            let payload = {username, password};
            const axiosResult = await axios.post("/ws/housekeepingapi/login", payload, {validateStatus: () => true});
            const {status} = axiosResult;
            switch (status) {
                case 200:
                    axiosResult.useDefaultPropertyId = true;
                    return [
                        newFulfilledAction(propertiesActionTypes.FETCH_PROPERTIES_DATA, axiosResult),
                        newFulfilledAction(userActionTypes.LOGIN, axiosResult),
                    ].map(a => dispatch(a));
                case 500: {
                    const msg = axiosResult.data.message;
                    return dispatch(newRejectedAction(userActionTypes.LOGIN, `${msg}`));
                }
                default:{
                    const msg = axiosResult.data.message;
                    return dispatch(newRejectedAction(userActionTypes.LOGIN, `${msg}`));
                }
            }
        } catch (error) {
            return dispatch(newRejectedAction(userActionTypes.LOGIN, `Error loggin in. ${error}`));
        }
    }
}

export function logout(reason, history) {
    return async (dispatch) => {
        deleteToken();
        deleteState();
        if (!!history) {
            history.replace("/login");
        }
        return dispatch(newFulfilledAction(userActionTypes.LOGOUT, reason));
    }
}

export function setLastVisitedAccordion(accordionIdx) {
    return async (dispatch) => dispatch(newFulfilledAction(userActionTypes.SET_LAST_VISITED_ACCORDION, accordionIdx));
}

export function updateLanguageStrings() {
    return async (dispatch) => dispatch(newFulfilledAction(userActionTypes.UPDATE_LANGUAGE_STRINGS));
}
