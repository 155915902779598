import React, {Component} from 'react';
import {
    Segment,
    Grid,
    Header,
    Button,
    Responsive,
    Loader,
    Dimmer,
    Message
} from "semantic-ui-react";
import {connect} from "react-redux";
import {startToCleanCommonAreaTask, updateCommonAreaTask} from "../actions/propertyActions";
import {isValidCleanableTypeName, FIVE_MINUTES, getCleanable} from "../utils/common";
import {setGoBackCallback} from "../actions/appActions";
import {commonAreaTaskStatuses} from "../components/cleanableCards/types";
import axios from "axios";


const mapStateToProps = (state, ownProps) => {
    const {properties, user} = state;
    const {selectedPropertyIds, date} = properties;

    return {
        ...ownProps,
        loadingTaskData: !!properties.loading,
        selectedPropertyIds,
        selectedDate: date,
        selectedProperties: properties.propertyList,
        displayText: user.displayText,
        currentRoomStatus: properties.currentRoomStatus,
    }
};

class CommonAreaTaskDetails extends Component {
    constructor(props) {
        super(props);
        const {match, history} = props;

        const id = parseInt(match.params.id, 10);
        const typeName = match.params.typeName;
        const invalidTypeName = !isValidCleanableTypeName(typeName);
        const propertyId = parseInt(match.params.propertyId, 10);

        if (isNaN(id) || !typeName || typeName === '' || invalidTypeName || isNaN(propertyId)) {
            history.push("/");
        }

        this.state = {
            id,
            propertyId,
            typeName,
            changedTaskStatus: false,
            cleaningKeepAliveInterval: null,
        }
    }

    componentDidMount() {
        const {dispatch} = this.props;
        const {id, propertyId, typeName} = this.state;

        dispatch(startToCleanCommonAreaTask(propertyId, typeName, id));
        dispatch(setGoBackCallback(this.handleBack));

        this.setState({
            cleaningKeepAliveInterval: setInterval(
                () => axios.put("/ws/housekeepingapi/checkCommonAreaStatus", {id: id}),
                FIVE_MINUTES
            )
        });
    }

    componentWillUnmount() {
        const {cleaningKeepAliveInterval,} = this.state;
        clearInterval(cleaningKeepAliveInterval);
        this.props.dispatch(setGoBackCallback(() => {}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {changedTaskStatus, displaySuccessMessage} = this.state;
        const {currentRoomStatus, history, location, displayText} = this.props;

        const showServiceRequestSubmitSuccess = (!!location && !!location.state && location.state.showServiceRequestSubmitSuccess) || false;
        if (!!showServiceRequestSubmitSuccess && !displaySuccessMessage) {
            setTimeout(() => this.setState({displaySuccessMessageText: ''}), 3000);
            this.setState({
                displaySuccessMessage: true,
                displaySuccessMessageText: displayText.serviceRequestSuccessful
            })
        }

        if (changedTaskStatus && currentRoomStatus === commonAreaTaskStatuses.DONE) {
            history.goBack();
        }
    }

    handleCheckboxOnClick = async () => {
        const {dispatch, selectedProperties} = this.props;
        const {id, propertyId, typeName} = this.state;
        const cleanable = getCleanable(selectedProperties, id, typeName, propertyId);

        this.setState({changedTaskStatus: true});
        cleanable.status = commonAreaTaskStatuses.DONE;
        dispatch(updateCommonAreaTask(propertyId, typeName, cleanable));
    };

    handleBack = async () => {
        const {history} = this.props;
        history.push("/");
    };

    render() {
        const {displayText, selectedProperties, loadingTaskData} = this.props;
        const {id, typeName, propertyId, displaySuccessMessageText} = this.state;
        const cleanable = getCleanable(selectedProperties, id, typeName, propertyId);

        return (
            <Segment raised>
                <Dimmer inverted active={!!loadingTaskData}>
                    <Loader>{displayText.loading}</Loader>
                </Dimmer>

                <Header as={'h3'} style={{margin: '0.5em'}}>
                    {displayText.commonArea} - {cleanable.commonAreaName} - {cleanable.name}
                </Header>
                <Header as={'h4'}
                        style={{marginLeft: '0.5em', marginBottom: '1em'}}>{displayText.Subtasks}</Header>

                <ul>{!!cleanable.subtask && cleanable.subtask.length > 0 ? (
                        cleanable.subtask.map(st => <li>{st}</li>)
                    ):(
                        <li>{displayText.noSubtasksSpecified}</li>
                    )
                }</ul>

                <Grid style={{marginTop: '0.5em'}}>
                    {!!displaySuccessMessageText && displaySuccessMessageText.length > 0 &&
                    <Grid.Row>
                        <Grid.Column>
                            <Message positive onDismiss={() => this.setState({displaySuccessMessageText: ''})}>
                                {displaySuccessMessageText}
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                    }

                    <Responsive as={Grid.Row} minWidth={Responsive.onlyComputer.minWidth}>
                        <Grid.Column textAlign={"right"}>
                            <div>
                                <Button secondary onClick={this.handleBack}>{displayText.back}</Button>
                                <Button primary onClick={this.handleCheckboxOnClick}>{displayText.markAsFinished}</Button>
                            </div>
                        </Grid.Column>
                    </Responsive>
                </Grid>
            </Segment>
        );
    }
}

export default connect(mapStateToProps)(CommonAreaTaskDetails);
