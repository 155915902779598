import moment from 'moment';
import {cardTypes} from "../components/cleanableCards/types";

export const FIVE_MINUTES = 5 * 60 * 1000;

export const development = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

export const nullUndef = (v) => typeof v === "undefined" || v === null || v === undefined;

export const getFormattedTime = (d) => moment(d).format("hh:mm A");

export const getFormattedDate = (d) => moment(d).format("DD-MM-YYYY");

export const toServerDateFormat = (d) => moment(d, "DD-MM-YYYY").format('YYYY-MM-DD');

export const toUiDateFormat = (d) => moment(d, 'YYYY-MM-DD').format('DD-MM-YYYY');

export const getDayName = (d) => moment(d).format('dddd');

export const isValidCleanableTypeName = (typeName) => {
    const matches = Object.keys(cardTypes).filter(type => cardTypes[type] === typeName);
    return matches.length > 0;
};

export const getSelectedProperties = (allProperties, selectedPropertyIds) => allProperties.filter(ap => selectedPropertyIds.filter(spi => spi === ap.id).length > 0);

export const getCleanable = (selectedProperties, id, typeName, propertyId) => {
    return selectedProperties.filter(sp => sp.id === propertyId)[0][typeName].filter(r => r.id === id)[0];
};
