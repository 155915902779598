import React, {Component} from 'react';
import {Form, Button, Segment, Header, Container, Loader, Dimmer, Message, Grid} from "semantic-ui-react";
import {login} from "../actions/userActions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const mapStateToProps = (state, ownProps) => {
    const {user} = state;
    return {
        ...ownProps,
        loginError: user.loginError,
        loginPending: user.loginPending,
    }
};

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            loginError: '',
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {loginPending, loginError, history} = this.props;
        const triedLogin = prevProps.loginPending === true && loginPending === false;

        if (triedLogin) {
            if (!!loginError) {
                this.setState({loginError})
            } else {
                history.push("/");
            }
        }
    }

    handleChange = (event, {name, value}) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({[name]: value, loginError: ''});
        }
    };

    login = () => {
        const {username, password} = this.state;
        const {dispatch} = this.props;
        dispatch(login(username, password));
    };

    render() {
        const {username, password, loginError} = this.state;
        const {loginPending} = this.props;
        const showErrorMsg = !!loginError && loginError.length > 0;

        return (
            <Container style={{marginTop: '1em'}}>

                <Grid textAlign={'center'} style={{height: '60vh'}} verticalAlign={'middle'}>
                    <Grid.Column style={{maxWidth: 450}}>
                        <Dimmer inverted active={!!loginPending}>
                            <Loader>Loading</Loader>
                        </Dimmer>

                        <Segment raised color={'blue'}>
                            <Header as={'h2'} textAlign={'center'}>
                                Housekeeping
                            </Header>
                            <Form>
                                <Form.Input icon={'user'}
                                            iconPosition={'left'}
                                            name={"username"}
                                            placeholder={"Username"}
                                            onChange={this.handleChange}
                                            value={username}
                                            fluid/>

                                <Form.Input icon={'lock'}
                                            iconPosition={'left'}
                                            name={"password"}
                                            placeholder={"Password"}
                                            onChange={this.handleChange}
                                            type={"password"}
                                            value={password}
                                            fluid/>

                                {showErrorMsg && <Message negative>{loginError}</Message>}
                                <Button fluid primary onClick={this.login}>Login</Button>
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
        )
    }
}

export default withRouter(connect(mapStateToProps)(Login));