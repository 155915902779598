import React, {Component} from 'react';
import './App.css';
import Home from "./pages/home";
import HeaderBar from "./components/headerBar"
import Login from "./pages/login";
import ServiceRequest from "./pages/serviceRequest";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {throttle} from "throttle-debounce";
import Config from "./components/config";
import Localized from "./components/localized";
import {Provider} from "react-redux";
import {store} from "./store";

import 'semantic-ui-css/semantic.min.css'
import {Container, Responsive} from "semantic-ui-react";
import OutInCleanings from "./pages/outincleanings";
import OutCleanings from "./pages/outcleanings";
import RoomDetails from "./pages/roomDetails";
import CommonAreaTaskDetails from "./pages/commonAreaTaskDetails";
import MaintenanceTaskDetails from "./pages/maintenanceTaskDetails";
import {persistState} from "./utils/localStorage";

store.subscribe(throttle(500, () => {
    persistState(store.getState());
}));

class App extends Component {
    renderRoutes = () => {
        return (
            <React.Fragment>
                <Route exact path={"/"} component={Home}/>
                <Route path={"/service/room/:roomId/type/:typeName/roomNumber/:roomNumber"}
                       component={ServiceRequest}/>

                <Route exact path={"/property/:propertyId/type/:typeName/room/:roomId"}
                       component={RoomDetails}/>

                <Route exact path={"/property/:propertyId/type/:typeName/commonAreaTask/:id"}
                       component={CommonAreaTaskDetails}/>

                <Route exact path={"/property/:propertyId/type/:typeName/task/:id"}
                       component={MaintenanceTaskDetails}/>

                <Route path={"/outincleanings"} component={OutInCleanings}/>
                <Route path={"/outcleanings"} component={OutCleanings}/>
            </React.Fragment>
        )
    };

    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <Config>
                        <Switch>
                            <Route exact path={"/login"} component={Login}/>
                            <Localized>
                                <HeaderBar children>
                                    <div className={'rootContainer'} style={{paddingTop:'3.3em'}}>
                                        <Responsive as={Container}      minWidth={1200}>{this.renderRoutes()}</Responsive>
                                        <Responsive as={React.Fragment} maxWidth={1200}>{this.renderRoutes()}</Responsive>
                                    </div>
                                </HeaderBar>
                            </Localized>
                        </Switch>
                    </Config>
                </BrowserRouter>
            </Provider>
        )
    }
}

export default App;
