import {combineReducers} from 'redux';
import {userReducer} from "./userReducer";
import {propertiesReducer} from "./propertiesReducer";
import {appReducer} from "./appReducer";


const allReducers = combineReducers({
    app: appReducer,
    user: userReducer,
    properties: propertiesReducer,
});

const rootReducer = (state, action) => {
    return allReducers(state, action)
};

export default rootReducer;