import axios from 'axios';
import {getToken} from "../utils/localStorage";
import {logout} from "../actions/userActions";

export function axiosConfig(dispatch, history) {
    axios.interceptors.request.use(function (config) {
        config.headers['Content-Type'] = 'application/json';

        try {
            const token = getToken();
            if (!config.url.includes('http') && !!token) {
                config.headers['Housekeeping-Token'] = token;
            }
        } catch (e) {
        }

        return config;
    });

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (!!error && !!error.response && error.response.status === 401) {
            dispatch(logout("Unauthorized", history));
            return error.response;
        }
    });
}
