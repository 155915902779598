import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import CleanableCard from "../cleanableCard";
import {stopCleanCommonAreaTask, resumeCleanCommonArea, startToCleanCommonAreaTask} from "../../actions/propertyActions";
import {cardTypes} from "./types";

const mapStateToProps = (state, ownProps) => {
    const {user, properties} = state;
    return {
        ...ownProps,
        displayText: user.displayText,
        language: user.language,
        showDirtyOnly: properties.showDirtyOnly,
        selectedProperties: properties.propertyList,
    }
};

class CommonAreaTaskCard extends Component {
    render() {
        const {propertyId, commonAreaTaskName, subTask, id, displayText, commonAreaName} = this.props;
        const {tasks, status, requestedCompletionTime, description, ...commonAreaTaskCardProps} = this.props;
        const descriptionWithTime = !!requestedCompletionTime? `${requestedCompletionTime} ${commonAreaTaskName}` : commonAreaTaskName;
        const fullDescription = <div><b>{commonAreaTaskName}</b><br/>{descriptionWithTime}</div>;

        return (
            <CleanableCard startToCleanFunc={startToCleanCommonAreaTask}
                           stopCleanFunc={stopCleanCommonAreaTask}
                           resumeCleanFunc={resumeCleanCommonArea}
                           status={status}
                           title={commonAreaName}
                           description={fullDescription}
                           tasks={!!subTask && subTask.length > 0? (
                                   subTask.map((s, idx) => {
                                       return {
                                           id: idx,
                                           name: s,
                                           completed: false,
                                       }
                                   })
                               ) : (
                                   [{name: displayText.viewTasksDetails, completed: false}]
                               )
                           }
                           detailsUrl={`/property/${propertyId}/type/${cardTypes.COMMON_AREA_TASKS}/commonAreaTask/${id}`}
                           showStartToCleanBtn={true}
                           showDoNotDisturbBtn={false}
                           disablePauseCleaning={true}
                           typeName={cardTypes.COMMON_AREA_TASKS}
                           {...commonAreaTaskCardProps}
            />
        );
    }
}

export default withRouter(connect(mapStateToProps)(CommonAreaTaskCard));
