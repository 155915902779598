import React, {Component} from "react";
import {Card, Button, Checkbox, Header, Grid, Label} from "semantic-ui-react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {cardTypes, roomStatuses, tasksStatuses, commonAreaTaskStatuses} from "./cleanableCards/types";
import {getCleanable} from "../utils/common";

const LIMIT_TASKS_TO_SHOW = 3;

const mapStateToProps = (state, ownProps) => {
    const {user, properties} = state;
    return {
        ...ownProps,
        displayText: user.displayText,
        language: user.language,
        showDirtyOnly: properties.showDirtyOnly,
        selectedProperties: properties.propertyList,
    }
};

class CleanableCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
        };
    }

    handleStartToCleanClick = () => {
        const {dispatch, propertyId, typeName, id, startToCleanFunc} = this.props;
        dispatch(startToCleanFunc(propertyId, typeName, id));
    };

    handlePauseCleaningClick = async () => {
        const {dispatch, selectedProperties, id, typeName, propertyId, pauseCleanFunc} = this.props;
        const cleanable = getCleanable(selectedProperties, id, typeName, propertyId);
        dispatch(pauseCleanFunc(propertyId, typeName, cleanable));
    };

    handleStopCleaningClick = async () => {
        const {dispatch, selectedProperties, id, typeName, propertyId, stopCleanFunc} = this.props;
        const cleanable = getCleanable(selectedProperties, id, typeName, propertyId);
        dispatch(stopCleanFunc(propertyId, typeName, cleanable));
    };

    handleResumeCleaningClick = async () => {
        const {dispatch, selectedProperties, id, typeName, propertyId, resumeCleanFunc} = this.props;
        const cleanable = getCleanable(selectedProperties, id, typeName, propertyId);
        dispatch(resumeCleanFunc(propertyId, typeName, cleanable));
    };

    handleOpenRoomDetailsClick = () => {
        const {detailsUrl, history, status} = this.props;
        if(status === roomStatuses.CLEANING || status === tasksStatuses.IN_PROGRESS || status === commonAreaTaskStatuses.IN_PROGRESS){
            history.push(detailsUrl);
        }
    };

    renderTasks = (tasks) => {
        const {displayText} = this.props;
        let displayTasks = tasks;
        let showPartialList = false;
        if (!!tasks && tasks.length > LIMIT_TASKS_TO_SHOW) {
            displayTasks = tasks.slice(0, LIMIT_TASKS_TO_SHOW);
            showPartialList = true;
        }

        return (!!tasks &&
            <div>
                {displayTasks.map((dt, i) => {
                    if(!!dt){
                        let displayLabel = !!dt.name? dt.name : "(no name)";
                        if(!!dt.inclusionDate && dt.inclusionDate.length > 0){
                            displayLabel = `${dt.name} (${dt.inclusionDate})`;
                        }
                        return (<div key={i}>
                            <Checkbox label={displayLabel} checked={dt.completed}/>
                        </div>)
                    } else {
                        return null;
                    }
                })}
                {showPartialList &&
                <div style={{marginLeft: '1em'}}><p><i>({displayText.showingPartialList})</i></p></div>}
            </div>
        )
    };

    getHeaderButtonConfig = (status) => {
        const {displayText, disablePauseCleaning, typeName} = this.props;

        const obj = {
            text: "",
            disable: false,
            color: "",
            inProgress: false,
            toBeResumed: false,
            onClick: () => this.handleStartToCleanClick(),
        };

        const text = {
            start: displayText.ucStartToClean,
            stop: displayText.ucStopCleaning,
            pause: displayText.ucPauseCleaning,
            resume: displayText.ucResumeCleaning,
        };

        if(typeName === cardTypes.COMMON_AREA_TASKS || typeName === cardTypes.MAINTENANCE_TASKS){
            text.start = displayText.ucStartTask;
            text.stop = displayText.ucStopTask;
            text.pause = displayText.ucPauseTask;
            text.resume = displayText.ucResumeTask;
        }

        switch (status) {
            case(roomStatuses.DIRTY):
            case(tasksStatuses.INCOMPLETE):
            case(commonAreaTaskStatuses.INCOMPLETE):
                obj.text = text.start;
                obj.color = "blue";
                break;
            case(roomStatuses.CLEANING):
            case(tasksStatuses.IN_PROGRESS):
            case(commonAreaTaskStatuses.IN_PROGRESS):
                if(!!disablePauseCleaning){
                    obj.text = text.stop;
                    obj.color = "red";
                    obj.onClick = () => this.handleStopCleaningClick();
                } else {
                    obj.text = text.pause;
                    obj.color = "grey";
                    obj.onClick = () => this.handlePauseCleaningClick();
                }

                obj.inProgress = true;
                break;
            case(roomStatuses.CLEANING_PAUSED):
                obj.text = text.resume;
                obj.color = "orange";
                obj.toBeResumed = true;
                obj.onClick = () => this.handleResumeCleaningClick();
                break;
            default:
                obj.disable = true;
        }

        return obj;
    };

    getDisplayStatusConfig = (status) => {
        const {displayText} = this.props;
        const text = displayText.cleanableStatuses[status];
        let color = "";
        switch (status) {
            case(roomStatuses.DIRTY):
            case(tasksStatuses.INCOMPLETE):
            case(commonAreaTaskStatuses.INCOMPLETE):
                color = 'red';
                break;
            case(roomStatuses.CLEAN):
            case(tasksStatuses.IN_PROGRESS):
            case(commonAreaTaskStatuses.IN_PROGRESS):
                color = 'yellow';
                break;
            case(roomStatuses.READY):
            case(tasksStatuses.DONE):
            case(commonAreaTaskStatuses.DONE):
                color = 'green';
                break;
            case(roomStatuses.CLEANING):
                color = 'blue';
                break;
            case(roomStatuses.CLEANING_PAUSED):
                color = 'orange';
                break;
            default:
                color = 'grey';
        }

        return {text, color};
    };

    updateDimensions = () => {
        this.setState({width: window.innerWidth});
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        const {
            roomNumber,
            tasks,
            showStartToCleanBtn,
            disableStartToCleanBtn,
            nextArrivalTime,
            content,
            showExtraTimeUntilArrival,
            propertyName,
            description,
            status,
            displayText,
            showDoNotDisturbBtn,
            showDirtyOnly,
            vacant,
            markAsDoNotDisturb,
            title,
            handleDoNotDisturbFunc,
            renderTimeUntilArrivalFunc,
        } = this.props;

        if (showDirtyOnly && (
            (   status !== roomStatuses.DIRTY &&
                status !== roomStatuses.CLEANING &&
                status !== roomStatuses.CLEANING_PAUSED &&
                status !== tasksStatuses.INCOMPLETE &&
                status !== tasksStatuses.IN_PROGRESS &&
                status !== commonAreaTaskStatuses.INCOMPLETE &&
                status !== commonAreaTaskStatuses.IN_PROGRESS
            ) || markAsDoNotDisturb
        )) {
            return null;
        }

        const {width} = this.state;
        const marginCardHeader = {margin: '0.25em'};
        const marginHeader = {margin: '0.25em'};
        const startToCleanBtnConfig = !!showStartToCleanBtn && this.getHeaderButtonConfig(status);
        const roomStatusConfig = this.getDisplayStatusConfig(status);

        let cardMinWidth = 25;
        let setFluid = false;
        const step = 14; // every 14 pixels decrease card size by 0.5em.
        for (let breakPoint = 764; breakPoint > width; breakPoint -= step) {
            if (width < breakPoint) {
                cardMinWidth -= 0.5;
            }
            if (width < 632) {
                setFluid = true;
            }/* else if (width < 936 && cardMinWidth < 25){
                cardMinWidth = 33;
            }
            */
        }

        const displayTitle = !!title ? title : `${displayText.room} #${roomNumber}`;
        const statusLabel = <Label size={'medium'} color={roomStatusConfig.color}>{roomStatusConfig.text}</Label>;
        const cardContentStyle = (status === roomStatuses.CLEANING) ? {backgroundColor: "#0101010d"} : {};
        const vacantStatus = (vacant === false)? ` - ${displayText.occupied}`:null;
        return (
            <Card color={status === roomStatuses.CLEANING ? 'black' : null} style={{minWidth: cardMinWidth + 'em'}}
                  fluid={setFluid}>
                <Card.Header style={{marginCardHeader}}>
                    {showDirtyOnly ? (
                        <Header as={'h3'} floated={'left'} style={marginHeader}>
                            {displayTitle}
                        </Header>
                    ) : (
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column width={7}>
                                    <Header as={'h3'} floated={'left'} style={marginHeader}>
                                        {displayTitle}
                                    </Header>
                                </Grid.Column>

                                <Grid.Column width={9}>
                                    <Header as={'h3'} floated={'right'} style={marginHeader}>
                                        <div className='ui two buttons'>
                                            {!!markAsDoNotDisturb &&
                                                <Label size={'medium'}
                                                    color={'orange'}>{displayText.doNotDisturb}</Label>
                                            }
                                            {statusLabel}
                                        </div>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )}
                </Card.Header>

                {!!description &&
                <Card.Header style={marginCardHeader}>
                     <div style={marginHeader}>{description}</div>
                </Card.Header>
                }

                <Card.Header style={marginCardHeader}>
                    <div style={marginHeader}>
                        <i>{propertyName}{vacantStatus}</i>
                    </div>
                </Card.Header>

                <Card.Header>
                    {!!showStartToCleanBtn && !startToCleanBtnConfig.disable &&
                    <div style={{margin: '0.4em'}}>
                        <div className='ui two buttons'>
                            <Button
                                primary={!disableStartToCleanBtn && !startToCleanBtnConfig.inProgress && !startToCleanBtnConfig.toBeResumed}
                                disabled={!!disableStartToCleanBtn || markAsDoNotDisturb}
                                color={!(disableStartToCleanBtn && !startToCleanBtnConfig.inProgress) ? startToCleanBtnConfig.color : 'grey'}
                                onClick={startToCleanBtnConfig.onClick}
                            >
                                {startToCleanBtnConfig.text}
                            </Button>

                            {showDoNotDisturbBtn && !!handleDoNotDisturbFunc &&
                            <Button
                                size={'mini'}
                                disabled={!!disableStartToCleanBtn || startToCleanBtnConfig.inProgress || markAsDoNotDisturb}
                                color={'grey'}
                                onClick={handleDoNotDisturbFunc}
                            >
                                {displayText.ucDoNotDisturb}
                            </Button>
                            }
                        </div>
                    </div>
                    }
                </Card.Header>

                <Card.Content style={cardContentStyle} onClick={this.handleOpenRoomDetailsClick}>
                    {content}
                    {this.renderTasks(tasks)}
                </Card.Content>

                {!!renderTimeUntilArrivalFunc && renderTimeUntilArrivalFunc(nextArrivalTime, !!showExtraTimeUntilArrival)}
            </Card>
        )
    }
}

export default withRouter(connect(mapStateToProps)(CleanableCard));
