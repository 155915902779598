import React, {Component} from "react";
import {Card, Message} from "semantic-ui-react";
import moment from 'moment';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getCleanable, nullUndef} from "../../utils/common";
import CleanableCard from "../cleanableCard";
import {patchRoomData, pauseCleanRoom, resumeCleanRoom, startToCleanRoom} from "../../actions/propertyActions";
import axios from "axios";

const mapStateToProps = (state, ownProps) => {
    const {user, properties} = state;
    return {
        ...ownProps,
        displayText: user.displayText,
        language: user.language,
        showDirtyOnly: properties.showDirtyOnly,
        selectedProperties: properties.propertyList,
    }
};

class RoomCard extends Component {
    handleDoNotDisturb = async () => {
        const {dispatch, selectedProperties, id, typeName, propertyId} = this.props;

        // TODO: improve loading
        const payload = {
            roomId: id,
            markRoomAsDoNotDisturb: true,
        };
        const axiosResult = await axios.put("/ws/housekeepingapi/changeRoomStatusAvailableToClean", payload);
        const room = getCleanable(selectedProperties, id, typeName, propertyId);

        room.markAsDoNotDisturb = axiosResult.data;
        dispatch(patchRoomData(propertyId, typeName, room));
    };

    renderTimeUntilArrival = (nextArrivalTime, showExtraTimeUntilArrival) => {
        let toRender = null;
        let extraTimeUntilArrival = null;

        if (!nullUndef(nextArrivalTime)) {
            const {displayText, language} = this.props;
            moment.locale(language);
            const diff = moment(nextArrivalTime).diff(moment.now());
            const timeLeft = moment.duration(diff);
            const minsLeft = Math.floor(timeLeft.asMinutes());
            extraTimeUntilArrival = timeLeft.humanize();

            if (timeLeft.asHours() <= 1) {
                if (timeLeft.asSeconds() >= 0) {
                    showExtraTimeUntilArrival = false;
                    toRender =
                        <Message warning>
                            {(minsLeft === 60 ? displayText.ucOneHour : `${minsLeft} ${displayText.ucMinutes} `) + displayText.ucUntilArrival}
                        </Message>
                } else {
                    extraTimeUntilArrival += displayText.ago;
                }
            } else {
                extraTimeUntilArrival += displayText.ucUntilArrival.toLowerCase();
            }

            if (showExtraTimeUntilArrival) {
                toRender = extraTimeUntilArrival;
            }

            if (toRender !== null) {
                toRender = <Card.Content extra>{toRender}</Card.Content>;
            }
        }

        return toRender;
    };

    render() {
        const {propertyId, typeName, id, roomStatus} = this.props;

        return (
            <CleanableCard startToCleanFunc={startToCleanRoom}
                           pauseCleanFunc={pauseCleanRoom}
                           resumeCleanFunc={resumeCleanRoom}
                           handleDoNotDisturbFunc={this.handleDoNotDisturb}
                           renderTimeUntilArrivalFunc={this.renderTimeUntilArrival}
                           detailsUrl={`/property/${propertyId}/type/${typeName}/room/${id}`}
                           status={roomStatus}
                           {...this.props}
                />
        );
    }
}

export default withRouter(connect(mapStateToProps)(RoomCard));