import React from "react";
import RoomCard from "./roomCard";
import {cardTypes} from "./types";

const CheckOutCheckInCard = ({
    propertyName,
    propertyId,
    roomNumber,
    id,
    roomStatus,
    roomTypeName,
    tasks,
    content,
    nextArrivalTime,
    currentArrivalTime,
    currentDepartureTime,
    vacant,
    markAsDoNotDisturb,
}) => {
    const showStartToCleanBtn = (!!tasks && tasks.length > 0);
    const disableStartToCleanBtn = !vacant;

    return (
        <RoomCard roomNumber={roomNumber}
                  tasks={tasks}
                  nextArrivalTime={nextArrivalTime}
                  currentArrivalTime={currentArrivalTime}
                  currentDepartureTime={currentDepartureTime}
                  showStartToCleanBtn={showStartToCleanBtn}
                  disableStartToCleanBtn={disableStartToCleanBtn}
                  content={content}
                  typeName={cardTypes.CHECKOUT_CHECKIN_CLEANINGS}
                  propertyName={propertyName}
                  propertyId={propertyId}
                  roomStatus={roomStatus}
                  roomTypeName={roomTypeName}
                  id={id}
                  vacant={vacant}
                  markAsDoNotDisturb={markAsDoNotDisturb}
        />
    )
};

export default CheckOutCheckInCard;