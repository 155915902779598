import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import CleanableCard from "../cleanableCard";
import {resumeCleanCommonArea, startToMaintenanceRoom, stopMaintenanceRoom} from "../../actions/propertyActions";
import {cardTypes} from "./types";

const mapStateToProps = (state, ownProps) => {
    const {user, properties} = state;
    return {
        ...ownProps,
        displayText: user.displayText,
        language: user.language,
        showDirtyOnly: properties.showDirtyOnly,
        selectedProperties: properties.propertyList,
    }
};

class MaintenanceTaskCard extends Component {
    render() {
        const {propertyId, name, id, maintenanceTaskStatus} = this.props;
        return (
            <CleanableCard startToCleanFunc={startToMaintenanceRoom}
                           stopCleanFunc={stopMaintenanceRoom}
                           resumeCleanFunc={resumeCleanCommonArea}
                           title={name}
                           detailsUrl={`/property/${propertyId}/type/${cardTypes.MAINTENANCE_TASKS}/task/${id}`}
                           status={maintenanceTaskStatus}
                           showStartToCleanBtn={true}
                           showDoNotDisturbBtn={false}
                           disablePauseCleaning={true}
                           typeName={cardTypes.MAINTENANCE_TASKS}
                           {...this.props}
                />
        );
    }
}

export default withRouter(connect(mapStateToProps)(MaintenanceTaskCard));
