import {fulfilled, pending, rejected} from "../utils/asyncStatusGenerator";
import {fetchPropertiesData, propertiesActionTypes} from "../actions/propertyActions";
import {getFormattedDate, FIVE_MINUTES, toUiDateFormat} from "../utils/common";

let fetchPropertiesDataInterval = {};

const initialState = {
    loading: false,
    loadingError: '',
    date: getFormattedDate(new Date()),
    propertyList: [],
    selectedPropertyIds: [],
    fetchPropertiesDataInterval: null,
    showDirtyOnly: true,
    currentRoomStatus: '',
};

export const propertiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case pending(propertiesActionTypes.FETCH_PROPERTIES_DATA):
        case fulfilled(propertiesActionTypes.FETCH_PROPERTIES_DATA):
        case rejected(propertiesActionTypes.FETCH_PROPERTIES_DATA):
            return handleFetchPropertiesData(state, action);

        case pending(propertiesActionTypes.FETCH_ROOM_DETAILS):
        case fulfilled(propertiesActionTypes.FETCH_ROOM_DETAILS):
        case rejected(propertiesActionTypes.FETCH_ROOM_DETAILS):
            return handleFetchRoomDetails(state, action);

        case pending(propertiesActionTypes.UPDATE_ROOM):
        case fulfilled(propertiesActionTypes.UPDATE_ROOM):
        case rejected(propertiesActionTypes.UPDATE_ROOM):
            return handleUpdateRoom(state, action);

        case fulfilled(propertiesActionTypes.START_PERIODIC_PROPERTIES_FETCH):
            return handlePeriodicPropertiesFetch(state, action);

        case fulfilled(propertiesActionTypes.SET_SHOW_DIRTY_ONLY):
            return handleDirtyOnlySwitch(state, action);

        default:
            return state;
    }
};

function handleFetchPropertiesData(state, action) {
    const {payload} = action;
    const data = !!payload && payload.data;

    switch (action.type) {
        case pending(propertiesActionTypes.FETCH_PROPERTIES_DATA):
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case fulfilled(propertiesActionTypes.FETCH_PROPERTIES_DATA):
            const {useDefaultPropertyId} = payload;
            const {properties, date, defaultPropertyId} = data;
            const selectedPropertyIds = !!useDefaultPropertyId ? [defaultPropertyId] : properties.map(p => p.id);
            return {
                ...state,
                loading: false,
                loadingError: '',
                date: toUiDateFormat(date),
                propertyList: properties,
                selectedPropertyIds,
            };
        case rejected(propertiesActionTypes.FETCH_PROPERTIES_DATA):
            return {
                ...state,
                loading: false,
                loadingError: payload
            };
        default:
            return {...state}
    }
}

function handleFetchRoomDetails(state, {payload, type: actionType}) {
    switch (actionType) {
        case pending(propertiesActionTypes.FETCH_ROOM_DETAILS):
            return {
                ...state,
                loading: true,
                loadingError: '',
                currentRoomStatus: '',
            };
        case fulfilled(propertiesActionTypes.FETCH_ROOM_DETAILS):
            const {propertyList} = state;
            const {propertyId, type, data: cleanableData} = payload;
            let cleanableId = null;
            const prop = propertyList.filter(pl => pl.id === propertyId)[0];

            prop[type].forEach((r, idx) => {
                if (r.id === cleanableData.id) {
                    cleanableId = idx
                }
            });
            if (cleanableId !== null) {
                prop[type][cleanableId] = cleanableData;
            }

            const r = prop[type][cleanableId];
            const status = (!!r.roomStatus)? r.roomStatus : (!!r.status)? r.status : r.maintenanceTaskStatus;
            return {
                ...state,
                loading: false,
                loadingError: '',
                propertyList: propertyList,
                currentRoomStatus: status,
            };
        case rejected(propertiesActionTypes.FETCH_ROOM_DETAILS):
            return {
                ...state,
                loading: false,
                loadingError: payload,
                currentRoomStatus: '',
            };
        default:
            return {...state}
    }
}

function handleUpdateRoom(state, action) {
    const {payload} = action;

    switch (action.type) {
        case pending(propertiesActionTypes.UPDATE_ROOM):
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case fulfilled(propertiesActionTypes.UPDATE_ROOM):
            const {propertyList} = state;
            const {propertyId, type, data: cleanableData} = payload;
            const prop = propertyList.filter(pl => pl.id === propertyId)[0];
            let cleanableId = null;
            prop[type].forEach((r, idx) => {
                if(r.id === cleanableData.id){
                    cleanableId = idx;
                }
            });
            prop[type][cleanableId] = {...cleanableData};
            const r = prop[type][cleanableId];
            const status = !!r.roomStatus? r.roomStatus : r.status;
            return {
                ...state,
                loading: false,
                loadingError: '',
                propertyList: propertyList,
                currentRoomStatus: status,
            };
        case rejected(propertiesActionTypes.UPDATE_ROOM):
            return {
                ...state,
                loading: false,
                loadingError: payload
            };
        default:
            return {...state}
    }
}

function handlePeriodicPropertiesFetch(state, {payload, type}) {
    if (type === fulfilled(propertiesActionTypes.START_PERIODIC_PROPERTIES_FETCH)) {
        let {dispatch, selectedPropertyIds, selectedDate} = payload;

        clearInterval(fetchPropertiesDataInterval);
        fetchPropertiesDataInterval = setInterval(() => dispatch(fetchPropertiesData(selectedPropertyIds, selectedDate)), FIVE_MINUTES);

        return {
            ...state,
            fetchPropertiesDataInterval: fetchPropertiesDataInterval,
        };
    }
}

function handleDirtyOnlySwitch(state, {payload}) {
    return {
        ...state,
        showDirtyOnly: payload,
    };
}
