import {newFulfilledAction, newPendingAction, newRejectedAction} from "../utils/asyncActionGenerator";
import axios from "axios";
import {toServerDateFormat} from "../utils/common";

export const propertiesActionTypes = {
    FETCH_PROPERTIES_DATA: "FETCH_PROPERTIES_DATA",
    FETCH_ROOM_DETAILS: "FETCH_ROOM_DETAILS",
    UPDATE_ROOM: "UPDATE_ROOM",
    START_PERIODIC_PROPERTIES_FETCH: "START_PERIODIC_PROPERTIES_FETCH",
    SET_SHOW_DIRTY_ONLY: "SET_SHOW_DIRTY_ONLY",
};

export function fetchPropertiesData(propertyIds, date) {
    return async (dispatch) => {
        dispatch(newPendingAction(propertiesActionTypes.FETCH_PROPERTIES_DATA));

        if(!propertyIds || propertyIds.length === 0){
            return dispatch(newRejectedAction(propertiesActionTypes.FETCH_PROPERTIES_DATA, "No propertyIds specified"));
        }

        try {
            const dateWithServerFriendlyFormat = toServerDateFormat(date);
            const payload = {propertyIds: propertyIds, date: dateWithServerFriendlyFormat};
            const axiosResult = await axios.post("/ws/housekeepingapi/listPropertyData", payload);
            const r = {properties:[], date: ""};
            r.date = dateWithServerFriendlyFormat;
            r.properties = axiosResult.data;

            return [
                newFulfilledAction(propertiesActionTypes.FETCH_PROPERTIES_DATA, {data: r}),
                newFulfilledAction(propertiesActionTypes.START_PERIODIC_PROPERTIES_FETCH, {dispatch, selectedPropertyIds: propertyIds, selectedDate: date})
            ].map(a => dispatch(a));
        } catch (error) {
            return dispatch(
                newRejectedAction(propertiesActionTypes.FETCH_PROPERTIES_DATA,
                    `Error fetching rooms data for propertyIds ${propertyIds}. ${error}`
                )
            );
        }
    }
}

export function patchRoomData(propertyId, typeName, data) {
    return async (dispatch) => {
        const payload = {};
        payload.data = data;
        payload.propertyId = propertyId;
        payload.type = typeName;

        return dispatch(newFulfilledAction(propertiesActionTypes.FETCH_ROOM_DETAILS, payload));
    }
}

export function startToCleanRoom(propertyId, typeName, id){
    return startToClean("/ws/housekeepingapi/startToClean", propertyId, typeName, id)
}

export function startToCleanCommonAreaTask(propertyId, typeName, id){
    return startToClean("/ws/housekeepingapi/startToCleanCommonAreaTask", propertyId, typeName, id)
}

export function startToMaintenanceRoom(propertyId, typeName, id){
    return startToClean("/ws/housekeepingapi/startToMaintenanceRoom", propertyId, typeName, id)
}

function startToClean(url, propertyId, typeName, cleanableId) {
    return async (dispatch) => {
        dispatch(newPendingAction(propertiesActionTypes.FETCH_ROOM_DETAILS));
        try {
            const payload = {id: cleanableId};
            const axiosResult = await axios.put(url, payload);
            axiosResult.propertyId = propertyId;
            axiosResult.type = typeName;

            return dispatch(newFulfilledAction(propertiesActionTypes.FETCH_ROOM_DETAILS, axiosResult));
        } catch (error) {
            return dispatch(
                newRejectedAction(propertiesActionTypes.FETCH_ROOM_DETAILS,
                    `Error fetching room ${cleanableId}. ${error}`
                )
            );
        }
    }
}

export function stopCleanCommonAreaTask(propertyId, typeName, commonArea) {
    return async (dispatch) => {
        dispatch(newPendingAction(propertiesActionTypes.FETCH_ROOM_DETAILS));
        try {
            const payload = {id: commonArea.id};
            const axiosResult = await axios.put("/ws/housekeepingapi/stopCleanCommonAreaTask", payload);
            commonArea.status = axiosResult.data.status;
            dispatch(patchRoomData(propertyId, typeName, commonArea));

            const fullfilledPayload = {
                data: commonArea,
                propertyId,
                type: typeName,
            };

            return dispatch(newFulfilledAction(propertiesActionTypes.FETCH_ROOM_DETAILS, fullfilledPayload));
        } catch (error) {
            return dispatch(
                newRejectedAction(propertiesActionTypes.FETCH_ROOM_DETAILS,
                    `Error in stop to clean room: ${commonArea.id}. ${error}`
                )
            );
        }
    }
}

export function stopMaintenanceRoom(propertyId, typeName, maintenanceTask) {
    return async (dispatch) => {
        dispatch(newPendingAction(propertiesActionTypes.FETCH_ROOM_DETAILS));
        try {
            const payload = {id: maintenanceTask.id};
            const axiosResult = await axios.put("/ws/housekeepingapi/stopMaintenanceRoom", payload);
            maintenanceTask.maintenanceTaskStatus = axiosResult.data.maintenanceTaskStatus;
            dispatch(patchRoomData(propertyId, typeName, maintenanceTask));

            const fullfilledPayload = {
                data: maintenanceTask,
                propertyId,
                type: typeName,
            };

            return dispatch(newFulfilledAction(propertiesActionTypes.FETCH_ROOM_DETAILS, fullfilledPayload));
        } catch (error) {
            return dispatch(
                newRejectedAction(propertiesActionTypes.FETCH_ROOM_DETAILS,
                    `Error in stop to maintenance task: ${maintenanceTask.id}. ${error}`
                )
            );
        }
    }
}

export function pauseCleanRoom(propertyId, typeName, room) {
    return changeRoomCleaningProgressStatus("/ws/housekeepingapi/changeRoomCleaningProgressStatus", propertyId, typeName, room, true);
}
export function resumeCleanRoom(propertyId, typeName, room) {
    return changeRoomCleaningProgressStatus("/ws/housekeepingapi/changeRoomCleaningProgressStatus", propertyId, typeName, room, false);
}
export function resumeCleanCommonArea(propertyId, typeName, room) {
    return changeRoomCleaningProgressStatus("/ws/housekeepingapi/changeCommonAreaCleaningProgressStatus", propertyId, typeName, room, false);
}
function changeRoomCleaningProgressStatus(url, propertyId, typeName, room, pauseCleaning) {
    return async (dispatch) => {
        dispatch(newPendingAction(propertiesActionTypes.FETCH_ROOM_DETAILS));
        try {
            const payload = {
                roomId: room.id,
                pauseCleaning,
            };
            const axiosResult = await axios.put(url, payload);
            room.roomStatus = axiosResult.data.cleaningRoomStatus;
            dispatch(patchRoomData(propertyId, typeName, room));

            const fullfilledPayload = {
                data: room,
                propertyId,
                type: typeName,
            };

            return dispatch(newFulfilledAction(propertiesActionTypes.FETCH_ROOM_DETAILS, fullfilledPayload));
        } catch (error) {
            return dispatch(
                newRejectedAction(propertiesActionTypes.FETCH_ROOM_DETAILS,
                    `Error changing the room cleaning progress status for room: ${room.id}. ${error}`
                )
            );
        }
    }
}

export function updateRoom(propertyId, typeName, room){
    return updateCleanable("/ws/housekeepingapi/updateRoomData", propertyId, typeName, room);
}

export function updateCommonAreaTask(propertyId, typeName, commonArea){
    return updateCleanable("/ws/housekeepingapi/updateCommonAreaTaskData", propertyId, typeName, commonArea);
}

export function updateMaintenanceTask(propertyId, typeName, maintenanceTask){
    return updateCleanable("/ws/housekeepingapi/updateRoomMaintenanceTask", propertyId, typeName, maintenanceTask);
}

function updateCleanable(url, propertyId, typeName, cleanable){
    return async(dispatch) => {
        dispatch(newPendingAction(propertiesActionTypes.UPDATE_ROOM, cleanable));
        try {
            const payload = {...cleanable};
            const axiosResult = await axios.put(url, payload);
            axiosResult.propertyId = propertyId;
            axiosResult.type = typeName;

            return dispatch(newFulfilledAction(propertiesActionTypes.UPDATE_ROOM, axiosResult));
        } catch (error) {
            return dispatch(
                newRejectedAction(propertiesActionTypes.UPDATE_ROOM,
                    `Error updating cleanable data. ${error}`
                )
            );
        }
    }
}

export function startPeriodicPropertiesFetch(propertyIds, date){
    return async(dispatch) => {
        dispatch(newFulfilledAction(propertiesActionTypes.START_PERIODIC_PROPERTIES_FETCH, {
            dispatch,
            selectedPropertyIds: propertyIds,
            selectedDate: date,
        }))
    }
}

export function setShowDirtyOnly(showDirtyOnly){
    return async(dispatch) => {
        dispatch(newFulfilledAction(propertiesActionTypes.SET_SHOW_DIRTY_ONLY, showDirtyOnly))
    }
}
