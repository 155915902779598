const languages = {
    en:{
        appTitle:"Housekeeping",
        loading: "Loading",
        logout: "Logout",
        properties: "Properties",
        coCiCleanings: "Check Out/Check In Cleaning",
        coCleanings: "Check Out Cleanings",
        serviceCleanings: "Service Cleanings",
        manualTasks: "Manual Tasks",
        commonAreas: "Common Areas",
        commonArea: "Common Area",
        maintenanceTasks: "Maintenance Tasks",
        nextCheckIn: "Next check-in",
        arrivingGuest: "Arriving guest. Expected arrival time",
        departingGuest: "Departing guest. Nights stayed",
        done: "Done",
        notDone: "Not done",
        cancel: "Cancel",
        submit: "Submit",
        type: "Type",
        request: "Request",
        back: "Back",
        markAsFinished: "Mark as finished",
        room: "Room",
        cleaningTasks: "Cleaning Tasks",
        Subtasks: "Subtasks",
        viewTasksDetails: "Click to go to details",
        newServiceRequest: "New Service Request",
        ago: "ago",
        showingPartialList: "showing partial list",
        ucOneHour : "1 HOUR",
        ucMinutes: "MINUTES",
        ucUntilArrival: "UNTIL ARRIVAL",
        ucInProgress: "IN PROGRESS",
        ucStartToClean: "START TO CLEAN",
        ucPauseCleaning: "PAUSE CLEANING",
        ucResumeCleaning: "RESUME CLEANING",
        ucStopCleaning: "STOP CLEANING",
        ucStartTask: "START TASK",
        ucPauseTask: "PAUSE TASK",
        ucResumeTask: "RESUME TASK",
        ucStopTask: "STOP TASK",
        ucDoNotDisturb: "DO NOT DISTURB",
        doNotDisturb: "Do not disturb",
        cleanableStatuses: {
            Dirty: "Dirty",
            Cleaning: "Cleaning",
            Ready: "Ready",
            Clean: "Clean",
            DIRTY: "Incomplete",
            INPROGRESS: "In progress",
            DONE: "Done",
            "Cleaning Paused": "Paused",
            "Tasks incomplete": "Incomplete",
            "Tasks In progress": "In progress",
            "Tasks Done": "Done",
        },
        dirtyOnly: "Dirty only",
        incompleteOnly: "Incomplete only",
        occupied: "ocuppied",
        noSubtasksSpecified: "No subtasks specified",
        loggedInAs: "Logged in as",
        pleaseSelectAType: "Please select a type",
        requestCantBeEmpty: "Request can't be empty",
        serviceRequestSuccessful: "Service request created successfully"
    },
    es:{
        appTitle:"Housekeeping",
        loading: "Cargando",
        logout: "Salir",
        properties: "Propiedades",
        coCiCleanings: "Limpieza de Salida/Entrada",
        coCleanings: "Limpieza de Salida",
        serviceCleanings: "Limpieza de servicios",
        manualTasks: "Tareas Manuales",
        commonAreas: "Áreas Comunes",
        commonArea: "Área Común",
        maintenanceTasks: "Tareas de mantenimiento",
        nextCheckIn: "Próximo check-in",
        arrivingGuest: "Huesped que llega. Horario de llegada",
        departingGuest: "Huesped en salida. Cantidad de noches",
        done: "Terminado",
        notDone: "No terminado",
        cancel: "Cancelar",
        submit: "Enviar",
        type: "Tipo",
        request: "Pedido",
        back: "Atrás",
        markAsFinished: "Marcar como finalizada",
        room: "Habitación",
        cleaningTasks: "Tareas de limpieza",
        Subtasks: "Subtareas",
        viewTasksDetails: "Click para ir a detalles",
        newServiceRequest: "Nuevo pedido de servicio",
        ago: "atrás",
        showingPartialList: "mostrando lista parcial",
        ucOneHour : "1 HORA",
        ucMinutes: "MINUTOS",
        ucUntilArrival: "HASTA LLEGADA",
        ucInProgress: "EN PROGRESO",
        ucStartToClean: "COMENZAR LIMPIEZA",
        ucPauseCleaning: "PAUSAR LIMPIEZA",
        ucResumeCleaning: "CONTINUAR LIMPIEZA",
        ucStopCleaning: "TERMINAR LIMPIEZA",
        ucStartTask: "COMENZAR TAREA",
        ucPauseTask: "PAUSAR TAREA",
        ucResumeTask: "CONTINUAR TAREA",
        ucStopTask: "TERMINAR TAREA",
        ucDoNotDisturb: "NO MOLESTAR",
        doNotDisturb: "No molestar",
        cleanableStatuses: {
            Dirty: "Sucia",
            Cleaning: "Limpiando",
            Ready: "Lista",
            Clean: "Limpia",
            DIRTY: "Incompleta",
            INPROGRESS: "En progreso",
            DONE: "Terminada",
            "Cleaning Paused": "Pausada",
            "Tasks incomplete": "Incompleta",
            "Tasks In progress": "En progreso",
            "Tasks Done": "Terminada",
        },
        dirtyOnly: "Solo sucias",
        incompleteOnly: "Solo incompletas",
        occupied: "ocupada",
        noSubtasksSpecified: "No especifica subtareas",
        loggedInAs: "Conectado como",
        pleaseSelectAType: "Seleccione un tipo",
        requestCantBeEmpty: "El pedido no puede estar vacío",
        serviceRequestSuccessful: "Pedido de servicio creado correctamente"
    },
};

export const getStrings = (lang) => {
    return languages[lang];
};
